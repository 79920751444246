import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import { useTranslation } from 'react-i18next';
import CountriesGrid from '../components/CountriesGrid';
import styles from '../styles/countries-styles';
import getHeaderLInks from '../shared/headerLinks.js';
import CountriesMapParallax from '../components/MapView/CountriesMapParallax';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import CheckCircle from '@material-ui/icons/CheckCircle';
import MapWrapper from '../components/GoogleMap/MapWrapper';

const { CountryWrapper } = styles;

const setProgramNames = (programName, countries) => {
  const output = [];
  if ((countries || []).length) {
    countries.map(country => {
      output.push({
        countryName: country.name,
        programName,
        coordinates: country.coordinates,
        url: country.url,
        countryID: country.countryID,
        isIntervention: country.isIntervention,
      });
    });
  }
  return output;
};

const Countries = ({ ...props }) => {
  const { t, ready } = useTranslation();
  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const { countries, screenName } = (data || {}).node || {};
  let { partnerCountries } = (data || {}).node || {};
  let programCountries = (partnerCountries || []).length
    ? setProgramNames(data.title, partnerCountries)
    : [];
  const otherCountries = (partnerCountries || []).filter(
    partnerCountry =>
      !(countries || []).find(
        country =>
          (country.title || '').toLowerCase() ===
          (partnerCountry.name || '').toLowerCase()
      )
  );
  const topCountires = (partnerCountries || []).filter(partnerCountry =>
    (countries || []).find(
      country =>
        (country.title || '').toLowerCase() ===
        (partnerCountry.name || '').toLowerCase()
    )
  );
  const allCountries = [...topCountires, ...otherCountries];

  const { headerLogo, headerLogoScroll } = (data || {}).node || {};

  const programLink = pageContext.programUrl;
  const headerLinks = getHeaderLInks('Countries', programLink, data.noLinks);

  const logos = pageContext.logos || [];
  const helmetLink = `${Constants.appUrl}/countries`;

  useEffect(() => {
    // console.log(countries)
  }, []);

  let countries_data =
    t('countries_data', {
      returnObjects: true,
    }) || [];

  const getCountryData = countryID => {
    let id = countryID;
    if (!ready) {
      return 'Loading...';
    } else {
      let data = countries_data.find(({ countryID }) => countryID == id);
      return data;
    }
  };

  return (
    <Layout
      programLink={programLink}
      title={t('countries')}
      imageName={'bg-country.jpg'}
      headerLinks={headerLinks}
      logos={logos}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'countries' }}
      screenName={screenName}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Investment Promotion Countries')}</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>

      <div className="container">
        <div className="countries-title">
          <h4>{t('intervention_countries')}</h4>
        </div>
      </div>

      {/* leaflet map view */}
      {/* <CountriesMapParallax
        style={{ width: '100%' }}
        countries={programCountries}
        programLink={programLink}
      /> */}
      {/* Google map view */}
      <MapWrapper
        style={{ width: '100%' }}
        countries={programCountries}
        programLink={programLink}
        clusterPage={true}
        pageTypes = {{industrials: false, partners: false, projects: false, countries: true}} 
      />

      <div className="container">
        <GridContainer>
          <GridItem md={6}>
            <div className="meso-title">
              <h4>
                {t('UNIDO – MESO LEVEL NATIONAL AND REGIONAL INTERVENTIONS')}
              </h4>
            </div>
          </GridItem>
          <GridItem className="meso-descrition" md={3} sm={12}>
            <p>
              {t(
                'Meso-level programme pillar aims to leverage intermediary organizations and business membership networks, including investment promotion institutions, especially investment promotion agencies to strengthen investment promotion capacities and capabilities, business support programmes, marketing, monitoring, and partnerships'
              )}
            </p>
          </GridItem>
          <GridItem className="meso-list" md={3} sm={12}>
            <ul>
              <li>
                <div className="meso-list-item">
                  <CheckCircle />
                  <p>{t('country_pt_1')}</p>
                </div>
              </li>
              <li>
                <div className="meso-list-item">
                  <CheckCircle />
                  <p>{t('country_pt_2')}</p>
                </div>
              </li>
              <li>
                <div className="meso-list-item">
                  <CheckCircle />
                  <p>{t('country_pt_3')}</p>
                </div>
              </li>
              <li>
                <div className="meso-list-item">
                  <CheckCircle />
                  <p>{t('country_pt_4')}</p>
                </div>
              </li>
              <li>
                <div className="meso-list-item">
                  <CheckCircle />
                  <p>{t('country_pt_5')}</p>
                </div>
              </li>
            </ul>
          </GridItem>
        </GridContainer>
      </div>

      <section className="current-intervension">
        <div className="container">
          <div className="title">
            {/* <h4>{t('Countries With Current Intervention')}</h4> */}
            <h4>{t('countries')}</h4>
          </div>
          <div className="country-list">
            {allCountries && allCountries.length > 1 ? (
              <CountryWrapper>
                {allCountries.map((country, index) => {
                  const { cover, background, description, countryID } = country;
                  let translatedCountryName = getCountryData(countryID)
                    ?.countryName;
                  return (
                    <CountriesGrid
                      key={index}
                      cover={((cover || {}).childImageSharp || {}).fluid || ''}
                      path={country.url ? programLink + country.url : ''}
                      title={country.name}
                      background={
                        ((background || {}).childImageSharp || {}).fluid || ''
                      }
                      description={description}
                      countryID={country.countryID}
                      translatedTitle={translatedCountryName}
                    />
                  );
                })}
              </CountryWrapper>
            ) : (
              ''
            )}

            {/* {countries && countries.length > 1 ? (
              // <div>
              <CountryWrapper>
                {countries.map((country, index) => {
                  const {
                    frontmatter,
                    rawMarkdownBody,
                  } = country.content.childMarkdownRemark;
                  const { cover, title, background, countryID } = frontmatter;
                  return (
                    <CountriesGrid
                      key={index}
                      cover={cover.childImageSharp.fluid}
                      path={country.url}
                      title={title}
                      background={((background || {}).childImageSharp || {}).fluid || ''}
                      description={rawMarkdownBody}
                      countryID={countryID}
                    />
                  );
                })}
              </CountryWrapper>
              // </div>
            ) : (
              ''
            )}
            {partnerCountries && partnerCountries.length > 1 ? (
              // <div>
              //   <span>
              <CountryWrapper>
                {partnerCountries.map((country, index) => {
                  const { cover, background, description } = country;
                  return (
                    <CountriesGrid
                      key={index}
                      cover={
                        ((cover || {}).childImageSharp || {}).fluid || ''
                      }
                      path={country.url ? programLink + country.url : ''}
                      title={country.name}
                      background={((background || {}).childImageSharp || {}).fluid || ''}
                      description={description}
                      countryID={country.countryID}
                    />
                  );
                })}
              </CountryWrapper>
              //   </span>
              //  </div>
            ) : (
              ''
            )} */}
          </div>
        </div>
      </section>
      {/* <section className="other-countries">
        <div className="container">
          <div className="title">
            <h4>{t('Other Countries')}</h4>
          </div>
          {partnerCountries && partnerCountries.length > 1 ? (
            <div>
              <span>
                <CountryWrapper>
                  {partnerCountries.map((country, index) => {
                    const { cover, background, description } = country;
                    return (
                      <CountriesGrid
                        key={index}
                        cover={
                          ((cover || {}).childImageSharp || {}).fluid || ''
                        }
                        path={country.url ? programLink + country.url : ''}
                        title={country.name}
                        background={((background || {}).childImageSharp || {}).fluid || ''}
                        description={description}
                        countryID={country.countryID}
                      />
                    );
                  })}
                </CountryWrapper>
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      </section> */}
    </Layout>
  );
};

export default Countries;

Countries.propTypes = {
  center: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object,
};
