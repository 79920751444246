import { css } from '@emotion/react';
import styled from '@emotion/styled';
import theme from '../../config/theme';

const ParagraphContent = css`
  p.description{
    color: ${theme.fontColor.body};
    font-size: 14px;
    line-height: 1.55em;
    text-align: justify;
    padding:0px;
    font-weight: ${theme.fontWeight.body};
    font-family: ${theme.fontFamily.body};
  }
  h2 {
    color: #3C4858;
    margin:40px 0px;
    margin-bottom: 15px;
    font-size:1.72rem;
    line-height: 1.55rem;
    text-align:center;
    font-weight: ${theme.fontWeight.heading};
    font-family: ${theme.fontFamily.heading};
    span
    {
      color: #00acc1;
    }
  }
  h4, h5 {
    color: #3C4858;
    margin:40px 0px;
    font-size:1.72rem;
    line-height: 1.55rem;
    text-align:center;
    font-weight: ${theme.fontWeight.heading};
    font-family: ${theme.fontFamily.heading};
  }
  h5 {
    font-size:1.52rem;
  }
  @media (max-width: 1024px) {

    p {
      padding:0px;
    }
    h4 {
      line-height: 40px;
    }
  }
  @media (max-width: 420px) {
    padding-left:0px;
    padding-right:0px;
    p {
      padding:0px;
    }
    h4 {
      font-size:1.12rem !important;
      line-height:25px;
    }
  }

}
`;

const container = css`
  padding-top: 2vh;
`;

const CountryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 4rem 1rem 1rem 1rem;
  @media (max-width: 1000px) {
    margin: 4rem 1rem 1rem 1rem;
  }
  @media (max-width: 700px) {
    margin: 0rem;
  }
`;

export default {
  CountryWrapper,
  ParagraphContent,
  container,
};
