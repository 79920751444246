import React, { useState } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';
import Flag from './Flag';
import Drawer from '@material-ui/core/Drawer';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { navigate } from 'gatsby';

const CountriesGrid = ({
  cover,
  path,
  title,
  background,
  description,
  translatedTitle,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [drawer, setDrawer] = useState(false);

  return (
    <>
      <div
        // onClick={() => {
        //   console.log(path)
        //   setDrawer(true);
        // }}
        onClick={() => {
          navigate(path);
        }}
        onKeyDown={e => {
          e.keyCode === 13 ? (e.preventDefault(), setDrawer(true)) : '';
        }}
        role="button"
        tabIndex={0}
        className="country-wrapper"
      >
        <Fade left>
          {(path || '').length ? (
            <>
              <div className="flag-image">
                <Img fluid={cover} alt="Flags" />
              </div>
              <div className="description">
                {/* <p>{t(title)}</p> */}
                <p>{translatedTitle}</p>
              </div>
            </>
          ) : (
            <>
              <div className="flag-image">
                <Flag imageName={title + '.png'} alt="Flags" />
              </div>
              <div className="description">
                <p>{t(title)}</p>
              </div>
            </>
          )}
        </Fade>
      </div>
      {mobileScreen ? (
        <Dialog
          fullScreen
          open={drawer}
          onClose={() => {
            setDrawer(false);
          }}
        >
          <div className="country-mob-info-wrapper">
            <div className="country-background">
              <div className="cancel">
                <IconButton
                  onClick={() => {
                    setDrawer(false);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </div>
              <div className="bg-image">
                <Img fluid={background} alt="background" />
              </div>
              <div className="flags-wrapper">
                <div className="flags">
                  <Img fluid={cover} alt="Flags" />
                </div>
              </div>
            </div>
            <div className="country-title">
              <h4>{t(title)}</h4>
            </div>
            <div className="country-description">
              <p dangerouslySetInnerHTML={{ __html: t(description) }}></p>
            </div>
          </div>
        </Dialog>
      ) : (
        <Drawer
          anchor="right"
          open={drawer}
          onClose={() => {
            setDrawer(false);
          }}
        >
          <div className="country-info-wrapper">
            <div className="country-background">
              <div className="cancel">
                <IconButton
                  onClick={() => {
                    setDrawer(false);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </div>
              <div className="bg-image">
                <Img fluid={background} alt="background" />
              </div>
              <div className="flags-wrapper">
                <div className="flags">
                  <Img fluid={cover} alt="Flags" />
                </div>
              </div>
            </div>
            <div className="country-title">
              <h4>{t(title)}</h4>
            </div>
            <div className="country-description">
              <p dangerouslySetInnerHTML={{ __html: t(description) }}></p>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default CountriesGrid;

CountriesGrid.propTypes = {
  cover: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  countryID: PropTypes.any.isRequired,
};
